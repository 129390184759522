import * as typesAdminGallery from "./types";

const initialState = {
  loading: false,
  photos: [], //якщо потрібно буде вернути
  isUploaded: false,
  isBadRequest: false,
  isLoadedList: false,
};

export const adminGalleryReducer = (state = initialState, action) => {
  switch (action.type) {
    case typesAdminGallery.IMAGE_UPLOAD_STARTED:
      return {
        ...state,
        loading: true,
      };
    case typesAdminGallery.IMAGE_UPLOAD_SUCCEDED:
      return {
        ...state,
        loading: false,
        isUploaded: action.isUploaded,
        photos: action.photos,
      };
    case typesAdminGallery.IMAGE_UPLOAD_FAILED:
      return {
        ...state,
        loading: false,
        isBadRequest: true,
      };
    case typesAdminGallery.IMAGE_GET_LIST_STARTED:
      return {
        ...state,
        loading: true,

      };
    case typesAdminGallery.IMAGE_GET_LIST:
      return {
        ...state,
        loading: false,
        pictures: action.pictures,
        isLoadedList: true
      };
    case typesAdminGallery.IMAGE_GET_LIST_FAILED:
      return {
        ...state,
        loading: false,
        isBadRequest: true,
      };
    case typesAdminGallery.IMAGE_DELETE_STARTED:
      return {
        ...state,
        loading: true,
      };
    case typesAdminGallery.IMAGE_DELETE_SUCCEDED:
      return {
        ...state,
        isLoadedList: true,
        loading: false,
        pictures: action.pictures,
      };
    case typesAdminGallery.IMAGE_DELETE_FAILED:
      return {
        ...state,
        isBadRequest: true,
      }
    default:
      break;
  }
  return state;
};
