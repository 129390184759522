import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import configureStore, { history } from './store/configureStore';
import * as loginActions from "./components/auth/loginUser/_actions/index";

const initialState = window.initialReduxState;
const store = configureStore(history, initialState);

if (localStorage.jwtToken) {
  let data = {
    token: localStorage.jwtToken,
    user: JSON.parse(localStorage.admin),
  };
  loginActions.loginByJWT(data, store.dispatch);
} else {
  localStorage.clear();
}



ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();