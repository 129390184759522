import { googleConstants } from '../_constants/google_types';

const initialState = {
    user: {},
    isSignedInByGoogle: false,
    isStartedGoogleLogin: false
}

export const googleLoginReducer = (state = initialState, action) => {
    switch (action.type) {
        case googleConstants.GOOGLE_LOGIN_START:
            return {
                ...state,
                isStartedGoogleLogin: true
            }
        case googleConstants.GOOGLE_LOGGED_IN:
            return {
                ...state,
                isSignedInByGoogle: true,
                user: action.data
            }
        case googleConstants.GOOGLE_LOGGED_OUT:
            return {
                ...state,
                isSignedInByGoogle: false,
                user: {},
            }
        default:
            break;
    }
    return state;
}