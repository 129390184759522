import * as types from './types';
const newPass = {
    loading: false,
    setupCompleted: false,
    errors: {}
}

export const newPassReducer = (state = newPass, action) => { //чекай - оцей редюсер не підключений
    switch(action.type){
        case types.NEWPASS_STARTED:
            return {
                loading: true,
                setupCompleted: false,
                errors: {}
            }
        case types.NEWPASS_SUCCESS:
            return {
                loading: false,
                setupCompleted: true,
                errors: {}
            }
        case types.NEWPASS_FAILED:
            return {
                loading: false,
                setupCompleted: false,
                errors: {}
            }
            default:
                break;
    }
    return state;
}