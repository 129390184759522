//import { combineReducers } from 'redux';
import * as types from "./types";
const getApp = {
  isGot: false,
  errors: {},
  data: [{}],
  appByIdItem: {},
  dataWithDelApp: [{}],
  appForEdit: {},
  freeHoursByAdmin: [{}],
  avaliableHours: [{}],
};

export const actionsAppointmentsReducer = (state = getApp, action) => {
  switch (action.type) {
    case types.APPOINTMENT_FAILED:
      return {
        ...state,
        isGot: false,
        errors: action.errors,
      };
    case types.APPOINTMENT_BY_ID:
      return {
        ...state,
        appForEdit: action.appForEdit,
        isGot: true,
        incorrect: action.incorrect,
      };
    case types.APPOINTMENT_DELETE_BY_ID:
      console.log("action.data", action.data);
      return {
        ...state,
        isGot: true,
        data: action.data,
        appDataWasDelete: true,
      };
    case types.APPOINTMENT_SUCCESS:
      return {
        ...state,
        isGot: true,
        errors: {},
        data: action.data,
      };
    case types.APPOINTMENT_ADD_NEW_ADMIN:
      return {
        ...state,
        isSucceeded: action.isSucceeded,
        data: action.data,
      };
    case types.APPOINTMENT_UPDATE_ADMIN:
      return {
        ...state,
        data: action.date,
      };
    case types.APPOINTMENT_GET_FREE_HOURS_ADMIN:
      return {
        ...state,
        avaliableHours: action.avaliableHours,
        errors: action.errors,
      };
    case types.APPOINTMENT_GET_TODAY_APP:
      return {
        ...state,
        data: action.data,
        errors: action.errors
      }  
    default:
      break;
  }
  return state;
};
