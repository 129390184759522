import * as types from './types';
const intialState = {
    loading: false,
    errors: {},
    isExist: false,
    email: "",
}

export const registerReducer = (state = intialState, action) => {
    switch (action.type) {
        case types.REGISTER_STARTED:
            return {
                loading: true,
                errors: {}
            }
        case types.REGISTER_SUCCESS:
            return {
                loading: false,
                errors: {},
                isExist: action.isExist,
                email: action.email,
            }
        case types.REGISTER_FAILED:
            return {
                loading: false,
                errors: action.errors
            }
        default:
            break;
    }
    return state;
}
