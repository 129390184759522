import axios from 'axios';
import { serverUrl } from '../../../../config';

export const googleLoginService = {
    googleLogin
};

function googleLogin(model){
    return axios.get(`${serverUrl}api/Account/loginWithGoogle?googleToken=${model.googleToken}`);
}
