import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { registerReducer } from '../components/auth/register/reducer';
import { restorePasswordReducer } from '../components/auth/restorePassword/reducer';
import { authentication } from '../components/auth/loginUser/_reducers/authentication.reducer';
import { googleLoginReducer } from '../components/auth/loginUser/_reducers/googleReducer';
import { newPassReducer } from '../components/auth/restorePassword/newpass/reducer'; 
import { appReducer } from '../components/appointments/reducer';
import { changePasswordReducer } from '../components/auth/changePassword/reducer';
import {actionsAppointmentsReducer} from '../components/admindoctor/adminListAppointments/reducer';
import { discardAppReducer } from '../components/appointments/discardAppointments/reducer';
import { adminGalleryReducer } from '../components/admindoctor/adminGallery/reducer';
import { userGalleryReducer } from '../components/userGallery/reducerGallery';
import { adminDiagnosesReducer } from '../components/admindoctor/adminDiagnoses/reducerDiagnosis';
import { adminAppForPacientReducer} from '../components/admindoctor/appForPacient/reducerAppForPacient';
import { adminConclusionsReduser } from '../components/admindoctor/adminConclusionsList/reducerConclusions';

// Create browser history to use in the Redux store
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
export const history = createBrowserHistory({ basename: baseUrl });

export default function configureStore(history, initialState) {
  //redusers живуть окремим життям в окремому файлі разом з middleware   
  const reducers = {
    register: registerReducer,
    restore: restorePasswordReducer,
    authentication: authentication,
    newpassword: newPassReducer, 
    appointment: appReducer,
    changePassword: changePasswordReducer,
    actionsAppointments: actionsAppointmentsReducer,
    googleLoginZ: googleLoginReducer,
    discardAppZ: discardAppReducer,
    adminGalleryReducerZ: adminGalleryReducer,
    userGalleryReducerZ: userGalleryReducer,
    diagnosesReducer: adminDiagnosesReducer,
    appforpacientReducerZ: adminAppForPacientReducer,
    adminConclusionsReduser: adminConclusionsReduser,
  };
  
  const middleware = [
    thunk,
    routerMiddleware(history)
  ];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === 'development';
  if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
    window.devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
    enhancers.push(window.devToolsExtension());
  }

  const rootReducer = combineReducers({
    ...reducers,
    router: connectRouter(history)
  });

  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );
}
