import axios from 'axios';
import { serverUrl } from '../../../../config';


export const googleLogoutService = {
    googleLogoutZ
}

function googleLogoutZ(){
    return new Promise((resolve)=>{
        resolve(false);
    });
}