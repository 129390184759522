import * as typesDiagnoses from "./typesDiagnoses";

const adminDData = {
  diagnoses: [{}],
  errors: {},
};

export const adminDiagnosesReducer = (state = adminDData, action) => {
  switch (action.type) {
    case typesDiagnoses.DIAGNOSIS_GETALL:
      return {
        ...state,
        diagnoses: action.diagnoses,
      };
    default:
      break;
  }
  return state;
};
