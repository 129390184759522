import * as typesDiscardApp from "./types";

const initialState = {
  user: {
    email: "",
    sms:"",
    userDoesntExist:true,
  },
  refused: false,
  firstStepSMS: false,
  errors: {},
};

export const discardAppReducer = (state = initialState, action) => {
  switch (action.type) {
    case typesDiscardApp.DISCARD_FIRST_STEP_SMS:
      return {
        ...state,
        firstStepSMS: true,
        sms: action.sms,
        loading: true,
      };
    case typesDiscardApp.DISCARDAPP_SUCCESS:
    return {
        ...state,
        user: action.user,
        loading: true,
      };
    case typesDiscardApp.DISCARDAPP_FAILED:
      return {
        ...state,
        errors: {},
        refused: false
      };
    default:
      break;
  }
  return state;
};
