import * as types from './types';

const resSetNewPassData = {
    setNewPass: false,
    errors: {},
    resDataNewPass: [{}]
}

export const changePasswordReducer = (state = resSetNewPassData, action) => {
    switch (action.type) {
        case types.NEWPASS_SUCCESS:
            return {
                ...state,
                setNewPass: true,
                data: action.data,
                errors: {}
            }
        case types.NEWPASS_FAILED:
            //console.log("------errors", action.errors);
            return {
                ...state,
                setNewPass: false,
                errors: action.errors
            }
        default: break;
    }
    return state;
}

