import './App.css';
import React, { Component, Suspense } from 'react';
//import MenuNavbar from './components/menu/Navbar'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import ProgressSpinner from './components/common/eclipse';


const Admin = React.lazy(() => import('./components/admindoctor/scenes/AdminPage'));
const User = React.lazy(() => import('./components/usersLayout/layout/UserLayout'));
class App extends Component {

  render() {
    return (
      <Suspense fallback={<ProgressSpinner />}>
        <Switch>
          <Route
            path="/user"
            name="User"
            render={(props) => <User {...props} />}
          />
          <Route
            path="/admin"
            name="Admin"
            render={(props) => <Admin {...props} />}
          />
          <Route
            exact
            path="/"
            render={() => {
              return (
                <Redirect to="/user" />)
            }}
          />
          )
        </Switch>
      </Suspense>
    );
  }
}

export default App;
