import * as types from "./types";

const initialState = {
  loading: false,
  isLoadedGallery: false,
  isEmptyList: true,
  gallery: [],
  errors: [],
};

export const userGalleryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GALLERY_LOADING_STARTED:
      return {
        ...state,
        loading: true,
      };
    case types.GALLERY_LOADED:
      return {
        ...state,
        loading: false,
        gallery: action.gallery,
        isLoadedGallery: true,
        isEmptyList: action.isEmptyList,
      };
    case types.GALLERY_ERROR_LOADING:
      return {
          ...state,
          errors: action.error
      };
    default:
      break;
  }
  return state;
};
