import * as types from './types';
const restorePassword = {
    loading: false,
    isRestored: false,
    errors: {}
}

export const restorePasswordReducer = (state = restorePassword, action) => {
    switch (action.type) {
        case types.RESTORE_STARTED:
            return {
                loading: true,
                isRestored: false,
                errors: {}
            }
        case types.RESTORE_SUCCESS:
            return {
                loading: false,
                isRestored: true,
                errors: {}
            }
        case types.RESTORE_FAILED:
            return {
                loading: false,
                isRestored: false,
                errors: action.errors
            }
        default:
            break;
    }
    return state;
}
