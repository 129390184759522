import * as typesConclusions from "./typesConclusions";

const adminConslusionsData = {
  error: "",
  listConclusions: [{}],
  isSuccess: false,
  loading: false,
  conclucion: {}
  //isEmptyList: true,
};

export const adminConclusionsReduser = (state = adminConslusionsData, action) => {
  switch (action.type) {
    case typesConclusions.CONCLUSION_STARTED:
      return {
        ...state,
        loading: true,
      };
    case typesConclusions.CONCLUSION_SUCCESS:
      return {
        ...state,
        isSuccess: action.isSuccess,
        loading: false,
        errors: action.errors,
        listConclusions: action.listConclusions,
        //isEmptyList: action.isEmptyList
      };
    case typesConclusions.CONCLUSION_GETBYID:
      return {
        ...state,
        isSuccess: action.isSuccess,
        loading: false,
        conclusion: action.conclusion
      }
    default:
      break;
  }
  return state;
};
