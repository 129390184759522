import { googleConstants } from '../_constants/google_types';
import { googleLoginService } from '../_services/googleAuthService';
import { push } from 'connected-react-router';
import { googleLogoutService } from '../_services/googleLogoutService';

export const googleActions = {
    googleLogin,
    googleLogoutZ,
};

function googleLogin(model) {
    return dispatch => {
        dispatch({ type: googleConstants.GOOGLE_LOGIN_START });
        googleLoginService.googleLogin(model)
            .then((response) => {
                dispatch({
                    type: googleConstants.GOOGLE_LOGGED_IN,
                    isSignedInByGoogle: true,
                    data: response.data
                });
            }, err => {
                dispatch({
                    type: googleConstants.GOOGLE_FAILED,
                    isSignedInByGoogle: false
                })
            }
            ).catch(err => {
                dispatch(push("/user/errorfromserver"));
            })
    }
}

function googleLogoutZ() {
    return (dispatch) => {
        googleLogoutService.googleLogoutZ()
            .then((response) => {
                dispatch({
                    type: googleConstants.GOOGLE_LOGGED_OUT,
                    isSignedInByGoogle: response,
                    user: {},
                });
                dispatch(push("/user/login"));
            }).catch(err => {
                console.log(err);
            })
    }
}