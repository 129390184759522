import * as typesAppForPacient from "./typesAppForPacient";

const adminAppFP = {
  isDoneAppForUser: false,
  error:""
};

export const adminAppForPacientReducer = (state = adminAppFP, action) => {
  switch (action.type) {
    case typesAppForPacient.APP_FOR_PACIENT_CREATE:
      return {
        ...state,
        isDoneAppForUser: action.isDoneAppForUser,
        error: action.error,
      };
    default:
      break;
  }
  return state;
};
