import { userConstants } from "../_constants";
import { userService } from "../_services";
import { alertActions } from "./";
import { push } from "connected-react-router";
import jwt, { JsonWebTokenError } from "jsonwebtoken";
import setAuthorisationToken from "../../../../utils/setAuthorisationToken";
export const userActions = {
  login,
  logout,
};

function login(model) {
  return (dispatch) => {
    dispatch(request({ model }));
    userService
      .login(model)
      .then(
        (response) => {
          var user = jwt.decode(response.data.token);
          response.data.user.userRoles = user.roles;
          dispatch(success(response.data.user)); //
          if (user.roles === "Admin") {
            dispatch(push("/admin"));
            localStorage.setItem("admin", JSON.stringify(response.data));
          } else {
            dispatch(push("/user/loggeduser"));
            localStorage.setItem("client", JSON.stringify(response.data));
          }
          setAuthorisationToken(response.data.token);
          localStorage.setItem("jwtToken", response.data.token);
        },
        (error) => {
          //dispatch(failure(error));
          dispatch(push("/user/logerror"));
          //dispatch(alertActions.error(error));
        }
      )
      .catch((error) => {
        console.log("------------user-action errors-------------");
        dispatch(push("/user/logerror"));
      });
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user: user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user: user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function logout(model) {
  userService.logout();
  return { type: userConstants.LOGOUT };
}

export const loginByJWT = (tokens, dispatch) => {
  const { token, user } = tokens;
  setAuthorisationToken(token);
  dispatch(success(user));
  function success(user) {
    return {
      type: userConstants.LOGIN_SUCCESS,
      user: user.user,
    };
  }
};
