import * as types from './types';

const initialState = {
    loading: false,
    user: {
        email: ''
    },
    errors: {},
    appTimes: [{}],
    isEmpty: false,
    freeHours: [],
    isVarified: false,
}

export const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.APPOINTMENT_GET_FREE_HOURS:
            return {
                ...state,
                loading: false,
                freeHours: action.freeHours,
            }
        case types.APPOINTMENT_GET_DATA:
            return {
                ...state,
                loading:false,
                appTimes: action.appTimes,
                isEmpty: action.isEmpty,
            }
        case types.APPOINTMENT_STARTED:
            return {
                ...state,
                loading: true,
                errors: {},
            }
        case types.APPOINTMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.user,
                errors: {},
                badRequest: false,
                freeHours:[],
                isVarified: false,
            }
        case types.APPOINTMENT_FAILED:
            return {
                ...state,
                loading: false,
                errors: {},
                badRequest:true
            }
        default:
            break;
    }

    return state;
}